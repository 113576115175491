import * as React from 'react';
import DefaultLayout from '../layouts/header-dark';
import Footer from '../layouts/footer';
import '../styles/_bootstrap.scss'
import './index.scss';

class Tracking extends React.PureComponent<{}, {}> {
    public render() {
        return (
            <>
                <DefaultLayout />
                <div className="page">
                    <div className="container-fluid hero"/>
                    <div className="container body">
                        <section className="section tracking">
                            {/* <!--<h1 className="padding-bottom-S">Have an order you want to track?</h1>-->  */}
                            <h1 className="padding-bottom-S">Where is my order?</h1>
                            <div className="delivery-tracker content">
                                <p className="text-M">Simply enter the order number or courier tracking reference below to track your order.</p>
                                <p className="text-M" style={{ display: "inline-block" }}>Can't find what you're looking for? &nbsp; </p>
                                <div className="help-action action" style={{ display: "inline-block" }}>
                                    <p className="text-M"><a className="pointer" onClick={() => window.alert("Our chat is currently down")}> Talk to the team</a></p>
                                </div>
                            </div>
                        </section>

                        <section className="section delivery-tracker form">
                            <form action="https://direktek.metapack.com/metatrack/track" method="get" target="output_frame" id="metatrack_form" className="form-inline">
                                <div className="row">
                                    <input type="hidden" name="retailerId" value="DirekTek" />
                                    <div className="form-group col-sm-5 col-sm-offset-1 col-xs-12" style={{ padding: "10px 15px" }}>
                                        <input type="text" name="custom1" id="custom1" className="form-control squared" title="Enter the Direktek order number" placeholder="DirekTek order number" style={{ borderBottom: "1px solid #ccc !important" }} />
                                    </div>
                                    <div className="form-group col-sm-5 col-xs-12" style={{ padding: "10px 15px" }}>
                                        <input type="text" name="trackingRef" id="trackingRef" className="form-control squared" title="Enter the couriers tracking reference" placeholder="Courier tracking reference" style={{ borderBottom: "1px solid #ccc !important" }} />
                                    </div>
                                    <div className="form-group col-sm-4 col-sm-offset-4 col-xs-12" style={{ padding: "10px 15px" }}>
                                        <input type="submit" onClick={(e) => this.metatrackButton(e)} className="btn btn-success squared" id="metatrack_button" value="Track order" />
                                    </div>
                                </div>
                            </form>
                            <iframe className="col-sm-8 col-sm-offset-2 col-xs-12" name="output_frame" id="output_frame" width="100%" height="500" frameBorder="0"/>
                        </section>
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    
    public getURLParameterByName(name) {
        name = name.replace(/[\[]/, "\[").replace(/[\]]/, "\]");
        var regex = new RegExp("[\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    public removeEmptyInputs() {
        const allInputs = document.querySelectorAll('#metatrack_form input[type=text]');
        let input;
        for (let i = 0; input = allInputs[i]; i++) {
            if (input.getAttribute('name') && !input.value) {
                input.setAttribute('name', '');
            }
        }
    };

    public resetEmptyInputs() {
        const allInputs = document.querySelectorAll('#metatrack_form input[type=text]');
        console.log(allInputs)
        for (let i = 0; i < allInputs.length; i++) {
            const input = allInputs[i]
            input.setAttribute('name', input.id);
        }
    };

    public metatrackButton(e: React.MouseEvent<HTMLInputElement>) {
        e.preventDefault();
        this.removeEmptyInputs();
        const form = document.getElementById('metatrack_form');
        (form as HTMLFormElement).submit();
        this.resetEmptyInputs();
    };

    // if (getURLParameterByName("ordernum")) {
    //   document.getElementById("custom1").value = getURLParameterByName("ordernum");
    //   this.removeEmptyInputs();
    //   (form as HTMLFormElement).submit();
    //   this.resetEmptyInputs();
    // }
    // if (getURLParameterByName("order")) {
    //   document.getElementById("custom1").value = getURLParameterByName("order");
    //   removeEmptyInputs();
    //   (form as HTMLFormElement).submit();
    //   resetEmptyInputs();
    // }
    // if (getURLParameterByName("trackingref")) {
    //   document.getElementById("trackingRef").value = getURLParameterByName("trackingref");
    //   removeEmptyInputs();
    //   (form as HTMLFormElement).submit();
    //   resetEmptyInputs();
    // }
    // if (getURLParameterByName("ref")) {
    //   document.getElementById("orderRef").value = getURLParameterByName("ref");
    //   removeEmptyInputs();
    //   (form as HTMLFormElement).submit();
    //   resetEmptyInputs();
    // }
}

export default Tracking; 